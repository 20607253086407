<template>
  <div class="border-2 border-gray-100 bg-white rounded-md">
    <div class="border-b pt-5 pl-6 pr-8">
      <div class="flex justify-between border-b">
        <p class="text-black font-medium pb-5">Feedbacks</p>
        <p class="text-black font-medium pb-5">Status</p>
      </div>

      <div class="overflow-y-scroll no-scrollbar h-97">
        <div
          v-for="(feedback, index) in feedbacks"
          :key="index"
          class="flex flex-row justify-between mt-2 py-5 border-b"
        >
          <div class="flex flex-col gap-4">
            <div class="flex">
              <div class="ml-4">
                <p class="font-medium">{{ feedback?.feedback }}</p>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <div
              class="status align-middle flex justify-left items-center w-max rounded-lg h-6 p-2"
              :class="{
                'bg-green-50 text-green': feedback?.feedback_type == 'positive',
                'bg-red-50 text-red': feedback?.feedback_type == 'negative',
              }"
            >
              <span
                class="text-md capitalize"
                :class="{
                  'text-green': feedback?.feedback_type == 'positive',
                  'text-red': feedback?.feedback_type == 'negative',
                }"
                >{{ feedback?.feedback_type }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between px-7 py-3 h-12">
      <p>See complete overview</p>
      <button
        class="flex items-center w-1/7 text-teal font-semibold opacity-80 cursor-pointer"
        @click="router.push({ name: 'feedbacks-overview-page' })"
      >
        Open<span class="material-icons text-lg ml-1">launch</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const feedbacks = computed(() => {
  return store.getters["patientSurveys/getSurveyFeedbacks"].data;
});
</script>
