<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 gap-6">
      <MopfButtonsGroup :buttons="buttons" />
    </div>
    <div
      class="xl:relative rounded overflow-hidden shadow-lg h-auto border-0 border-gray-100 py-10 bg-white"
    >
      <div class="flex justify-between">
        <div class="pl-18">
          <span
            class="text-4xl text-textBlue-Default font-medium tracking-tighter"
            >Survey Responses</span
          >
        </div>
        <div class="flex space-x-4 justify-end pr-10 w-2/3 cursor-pointer">
          <v-select
            class="w-72"
            label="label"
            placeholder="Show By"
            :searchable="false"
            :disabled="statsLoaded === true"
            :options="shoyByOptions"
            v-model="surveyResponseOrder"
          >
          </v-select>
          <v-select
            class="w-72"
            label="practice_name"
            placeholder="Practice"
            :disabled="statsLoaded === true"
            :options="practices"
            @update:modelValue="surveyResponsePractice"
          >
          </v-select>
          <v-select
            class="w-72"
            label="question"
            placeholder="Questions"
            :options="surveyQuestions"
            @update:modelValue="surveyResponseQuestion"
          >
          </v-select>
        </div>
      </div>
      <span class="text-4xl font-medium text-textBlue-Default pl-18">{{
        surveyCount
      }}</span>
      <div :key="surveyResponseOrder" v-if="statsLoaded === false">
        <SurveyResponseChart
          :key="optionStats"
          :order="surveyResponseOrder?.value"
          :data="optionStats"
          class="h-full"
        />
      </div>
      <div v-else>
        <SurveyPracticeChart
          :key="practiceStats"
          :data="practiceStats"
          class="h-full"
        />
      </div>
    </div>
    <div class="border-2 border-gray-100 rounded-md bg-white">
      <SurveyQuestionsTable
        :key="surveyQuestions"
        :tableData="surveyQuestions"
        :tableHeadings="surveyTableHeadings"
        :loader="loadingQuestionTable"
      />
    </div>
    <div class="border-2 border-gray-100 bg-white rounded-md">
      <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
        <div class="justify-items-start p-4 text-lg w-full">
          <p>Action Plans</p>
        </div>
        <div class="flex space-x-4 justify-end p-4 w-2/3 z-10">
          <v-select
            class="w-72"
            label="practice_name"
            placeholder="Practice"
            :options="practices"
            @update:modelValue="actionPlanPractice"
          >
          </v-select>
          <v-select
            class="w-72"
            label="label"
            placeholder="Status"
            :searchable="false"
            :options="statusList"
            @update:modelValue="actionPlanStatus"
          >
          </v-select>
        </div>
      </div>
      <ActionPlanTable
        :key="actionPlansTableData?.data"
        :tableData="actionPlansTableData?.data"
        :tableHeadings="tableHeadings"
        :routeName="actionPlanDetailsRoute"
        :loader="loadingActionPlanTable"
      />
      <Pagination
        v-if="actionPlansTableData?.data?.length > 0"
        :currentPage="actionPlansTableData?.current_page"
        :totalPages="actionPlansTableData?.last_page"
        @page-changed="fetchActionPlanPage"
      />
    </div>
    <div class="grid sm:grid-col-1 lg:grid-cols-2 gap-6">
      <SurveyList />
      <FeedbackList />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Pagination from "@/components/newUi/atoms/Pagination.vue";

import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
import SurveyResponseChart from "@/components/newUi/charts/SurveyResponseChart.vue";
import SurveyList from "@/components/newUi/PatientSurveys/SurveyList.vue";
import FeedbackList from "@/components/newUi/PatientSurveys/FeedbackList.vue";
import ActionPlanTable from "@/components/newUi/tables/ActionPlanTable.vue";
import SurveyQuestionsTable from "@/components/newUi/tables/SurveyQuestionsTable.vue";
import SurveyPracticeChart from "@/components/newUi/charts/SurveyPracticeChart.vue";
const loadingActionPlanTable = ref(true);
const loadingFeedbackTable = ref(true);
const loadingQuestionTable = ref(true);
const store = useStore();
const statusList = ref([
  { label: "In-Progress", value: "in-progress" },
  { label: "Completed", value: "completed" },
]);
const shoyByOptions = ref([
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
]);
const tableHeadings = ref([
  "Agenda",
  "Practice",
  "Creation Date",
  "Meeting Count",
  "Meeting Date",
  "Agenda Status",
  "Time Frame",
  "",
]);
const surveyTableHeadings = ref(["Name", "Percentage", "Average", ""]);
const buttons = ref([
  {
    component: "hq-createSurveys",
    text: "Create Survey",
    icon: "tab",
    bgColor: "bg-yellow",
    textColor: "text-yellow",
  },
  {
    component: "hq-createActionPlan",
    text: "Create Action Plan",
    params: { qof: false },
    icon: "backup_table",
    bgColor: "bg-aquamarine",
    textColor: "text-aquamarine",
  },
]);
const actionPlanDetailsRoute = ref("hq-actionPlan-detail-ps");
const status = ref(null);
const practices = ref([]);
const surveyResponseOrder = ref(null);
const actionPlanStatusValue = ref(null);
const actionPlanFilterPractice = ref(null);
const practiceStats = ref(null);
const statsLoaded = ref(false);

onMounted(async () => {
  await store.dispatch("patientSurveys/fetchAllSurveys");
  await store.dispatch("patientSurveys/fetchSurveyFeedbacks").then(() => {
    loadingFeedbackTable.value = false;
  });
  await store.dispatch("patientSurveys/fetchSurveyCount");
  await store.dispatch("patientSurveys/fetchQuestions");
  await store.dispatch("patientSurveys/fetchOptionStats");
  fetchAllActionPlans({ tag: "patient-survey", page: 1 });
  await store.dispatch("patientSurveys/fetchQuestionStats").then(() => {
    loadingQuestionTable.value = false;
  });
  await store.dispatch("patientSurveys/loadPractices").then((response) => {
    practices.value = response;
  });
});

const fetchAllActionPlans = async (payload) => {
  loadingActionPlanTable.value = true;
  await store.dispatch("actionPlans/fetchAllActionPlans", payload).then(() => {
    loadingActionPlanTable.value = false;
  });
};

const actionPlansTableData = computed(() => {
  return store.getters["actionPlans/getActionPlans"];
});

const optionStats = computed(() => {
  return store.getters["patientSurveys/getOptionStats"];
});

const surveyCount = computed(() => {
  return store.getters["patientSurveys/getsurveyCount"];
});

const actionPlanStatus = (status) => {
  actionPlanStatusValue.value = status;
  fetchAllActionPlans({
    tag: "patient-survey",
    status: status ? status.value : status,
    practice: actionPlanFilterPractice.value?.id,
    page: 1,
  });
};

const actionPlanPractice = (practice) => {
  actionPlanFilterPractice.value = practice;
  const status = actionPlanStatusValue.value;
  fetchAllActionPlans({
    tag: "patient-survey",
    status: status ? status.value : status,
    practice: actionPlanFilterPractice.value?.id,
    page: 1,
  });
};

const surveyQuestions = computed(() => {
  return store.getters["patientSurveys/getQuestionStats"];
});

const fetchActionPlanPage = (page) => {
  if (page > actionPlansTableData.value.last_page) {
    return;
  } else {
    fetchAllActionPlans({
      tag: "patient-survey",
      status: status.value?.value,
      practice: actionPlanFilterPractice.value?.id,
      page: page,
    });
  }
};

const surveyResponsePractice = async (practice) => {
  surveyResponseOrder.value = null;
  loadingQuestionTable.value = true;
  await store
    .dispatch("patientSurveys/fetchOptionStats", {
      practice: practice ? practice.id : practice,
    })
    .then(() => {
      loadingQuestionTable.value = false;
    });
};

const surveyResponseQuestion = async (question) => {
  if (question) {
    await store
      .dispatch("patientSurveys/fetchPracticeStats", {
        survey_question: question?.id,
        page: 1,
      })
      .then((response) => {
        statsLoaded.value = true;
        practiceStats.value = response.data;
      });
  } else {
    statsLoaded.value = false;
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
